<template>
  <v-card>
    <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header">{{ $t('generic.lang_gui_messages') }}</v-card-title>
    <v-card-text class="pa-2">
      <v-row class="mx-0">
        <v-col v-if="this.send_loading" cols="12" class="pb-0">
          <v-alert color="info">
            <span class="text-white">
             {{ $t('generic.lang_broadcastEmailsDontReloadThisPage') }}
            </span>
          </v-alert>
        </v-col>
        <v-col cols="6">
          <v-select :label="$t('accounting.lang_classes')" :items="allClasses" outlined dense multiple v-model="form.classes"
                    :loading="loading||loadingClasses" item-text="name" item-value="uuid" hide-details
                    @change="getAllGroups">

          </v-select>
        </v-col>
        <v-col cols="6">
          <v-select :disabled="!(form.classes && form.classes.length<=1)" :label="$t('generic.lang_groups')" :items="allGroups" outlined
                    dense
                    multiple v-model="form.groups" item-text="name" item-value="uuid" :loading="loading||loadingGroups"
                    hide-details>

          </v-select>
        </v-col>
        <v-col cols="12">
          <v-select v-model="form.emailTemplate" :items="emailTemplates" :label="$t('generic.lang_emailTemplate')"
                    class="rounded-lg mb-2"
                    return-object :loading="loading||loadingTemplates" @change="fillTemplate"
                    item-text="tmplName" style="border-color: transparent !important;" outlined no-resize dense
                    hide-details></v-select>
        </v-col>
        <v-col class="pa-0 ma-0" cols="12">
          <v-form lazy-validation ref="form">
            <v-row class="pa-0 ma-0">
              <v-col class="pt-0 pb-12 mt-0" cols="12">
                <quill-editor id="editor" :data-layout="KEYBOARD.KEYSETS.NORMAL" :options="quillOptions"
                              :rules="[v => !!v]"
                              @focus="showTouchKeyboard"
                              output="html" v-model="form.body"></quill-editor>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-footer text-right pt-1 pb-1 mt-3">
      <v-spacer/>
      <v-btn class="bg-danger text-light mx-0">
        {{ this.$t('generic.lang_cancel') }}
      </v-btn>
      <v-btn
          :disabled="loading || loadingClasses || loadingGroups || loadingTemplates || form.body.length<10 || send_loading"
          :loading="loading || send_loading" @click="sendAll"
          class="bg-primary text-light mx-1">
        {{$t('support.lang_supportSend')}}
      </v-btn>
    </v-card-actions>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                          :accept="hideTouchKeyboard"
                          :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidth"/>
    </div>
  </v-card>
</template>
<script>
import {ENDPOINTS} from "@/config";

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor} from 'vue-quill-editor';

import mixin from "@/mixins/KeyboardMixIns";
import {Events} from "@/plugins/events";

export default {
  name: "Messaging",
  components: {quillEditor},
  mixins: [mixin],
  data() {
    return {
      form: {
        classes: [],
        groups: [],
        body: '',
        emailTemplate: null,
        subject: ''
      },
      send_loading: false,
      loading: false,
      allClasses: [],
      allGroups: [],
      templates: [],
      loadingTemplates: false,
      loadingClasses: false,
      loadingGroups: false,
      emailTemplates: [],
      template: '',
      quillOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
            [{'align': []}],
            [{'font': []}],
          ],
        },
      },
    }
  },
  computed: {},
  watch: {
    "form.classes": function (val) {
      if ((Array.isArray(val) && val.length > 1) || (Array.isArray(val) && val.length === 0))
        this.form.groups = []
    }
  },
  methods: {
    /**
     * send broadcast to selected class & groups
     */
    sendAll() {
      this.send_loading = true;
      this.axios.post(ENDPOINTS.KANTINE.SETTINGS.EMAILS.BROADCAST, {
        classes: this.form.classes,
        groups: this.form.groups,
        template: this.form.emailTemplate.id,
        body: this.form.body,
        subject: this.form.emailTemplate.tmplName
      }).then((res) => {

        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_success'),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg || this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.send_loading = false;
      })
    },
    getAllClasses() {
      this.loadingClasses = true;
      this.axios.post(ENDPOINTS.KANTINE.SETTINGS.CLASSES.GET).then(res => {
        this.allClasses = res.data.classes;
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        })
      }).finally(() => {
        this.loadingClasses = false;
      });
    },
    getAllGroups() {
      if (!(this.form.classes.length > 0 && this.form.classes.length <= 1)) {
        return;
      }
      this.loadingGroups = true;
      this.axios.post(ENDPOINTS.KANTINE.SETTINGS.GROUPS.GET, {
        classUUID: this.form.classes[0]
      }).then(res => {
        this.allGroups = res.data.groups;
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        })
      }).finally(() => {
        this.loadingGroups = false;
      });
    },
    getAllTemplates() {
      this.loadingTemplates = true;
      this.axios.post(ENDPOINTS.KANTINE.SETTINGS.EMAILS.TEMPLATES.GETALL)
          .then((res) => {
            this.emailTemplates = res.data
          }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingTemplates = false;
      })
    },
    fillTemplate(template) {
      this.form.body = template.text;
      if (!template.text || template.text.length < 1) {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_theSelectedTemplateIsEmpty'),
          color: "warning"
        })
      }
    }
  },
  mounted() {
    this.getAllClasses();
    this.getAllTemplates()
  }
}
</script>

<style scoped>
#editor {
  height: 100px !important;
}
</style>
